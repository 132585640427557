import React, { useState, useEffect } from 'react';
import { Logo } from '../assets/Branding';
import { PrimaryButton } from '../components/Buttons';

// Navbar component definition
export default function Navbar() {
  const [activeSection, setActiveSection] = useState('');

  // UseEffect to observe section visibility changes
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id); // Update active section state
        }
      });
    }, { rootMargin: '-50% 0px -50% 0px' });

    // Attaching observer to each section
    document.querySelectorAll('section').forEach((section) => {
      observer.observe(section);
    });

    // Cleanup observer on component unmount
    return () => observer.disconnect();
  }, []);

  // Updated navigation function to scroll to section by ID
  const navigateToSection = (sectionId: string) => {
    const targetSection = document.getElementById(sectionId);

    if (targetSection) {
      const offsetPosition = targetSection.offsetTop - 120; // Offset by navbar height
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  return (
    <nav className="navbar">
      <a href="/" className="navbar-logo">
        <Logo />
        <strong className="navbar-logo-text">Llamaduck</strong>
      </a>

      <div className="nav-menu">
        <a href="#services" className={`nav-link ${activeSection === 'services' ? 'active-nav-item' : ''}`} onClick={(e) => { e.preventDefault(); navigateToSection('services'); }}>Our services</a>
        <a href="#projects" className={`nav-link ${activeSection === 'projects' ? 'active-nav-item' : ''}`} onClick={(e) => { e.preventDefault(); navigateToSection('projects'); }}>Projects</a>
        <a href="#about" className={`nav-link ${activeSection === 'about' ? 'active-nav-item' : ''}`} onClick={(e) => { e.preventDefault(); navigateToSection('about'); }}>About</a>
        <a href="#contact" className={`nav-link ${activeSection === 'contact' ? 'active-nav-item' : ''}`} onClick={(e) => { e.preventDefault(); navigateToSection('contact'); }}>Contact</a>
        <PrimaryButton title="Get in touch" onClick={() => navigateToSection('contact')}/>
      </div>
    </nav>
  )
}
