import React, {SVGProps} from 'react';

export function InnovationIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={42}
        height={45}
        fill="none"
        {...props}
    >
        <path
        stroke="#0B3183"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M5 31.667V17c0-1.867 0-2.8.363-3.513.32-.627.83-1.137 1.457-1.457.713-.363 1.647-.363 3.514-.363h19.333c1.867 0 2.8 0 3.513.363.627.32 1.137.83 1.457 1.457C35 14.2 35 15.133 35 17v14.667h-8.895c-.408 0-.612 0-.804.046-.17.04-.332.108-.481.2-.169.102-.313.247-.6.535l-.105.104c-.289.289-.433.433-.601.536-.15.091-.312.158-.482.2-.192.045-.395.045-.803.045H17.77c-.407 0-.611 0-.803-.046a1.667 1.667 0 0 1-.482-.2c-.168-.102-.312-.246-.6-.535l-.105-.104c-.288-.288-.432-.433-.6-.536a1.668 1.668 0 0 0-.482-.2c-.192-.045-.396-.045-.803-.045H5Zm0 0c-.92 0-1.667.746-1.667 1.666v.556c0 1.033 0 1.55.114 1.974a3.333 3.333 0 0 0 2.357 2.357c.424.113.94.113 1.974.113h24.444c1.034 0 1.55 0 1.974-.113a3.334 3.334 0 0 0 2.357-2.357c.114-.424.114-.94.114-1.974 0-.517 0-.775-.057-.987a1.667 1.667 0 0 0-1.178-1.178c-.212-.057-.47-.057-.987-.057h-1.111"
        />
        <path
        fill="#fff"
        d="m29 2 1.302 5.206c.253 1.016.38 1.524.645 1.937a3 3 0 0 0 .91.91c.413.265.921.391 1.937.645L39 12l-5.206 1.302c-1.016.254-1.524.38-1.937.645a2.999 2.999 0 0 0-.91.91c-.265.413-.392.921-.645 1.937L29 22l-1.302-5.206c-.253-1.016-.38-1.524-.645-1.937a2.999 2.999 0 0 0-.91-.91c-.413-.265-.921-.391-1.937-.645L19 12l5.206-1.302c1.016-.254 1.524-.38 1.937-.645a3 3 0 0 0 .91-.91c.265-.413.392-.921.645-1.937L29 2Z"
        />
        <path
        fill="#fff"
        d="m29 2 1.302 5.206c.253 1.016.38 1.524.645 1.937a3 3 0 0 0 .91.91c.413.265.921.391 1.937.645L39 12l-5.206 1.302c-1.016.254-1.524.38-1.937.645a2.999 2.999 0 0 0-.91.91c-.265.413-.392.921-.645 1.937L29 22l-1.302-5.206c-.253-1.016-.38-1.524-.645-1.937a2.999 2.999 0 0 0-.91-.91c-.413-.265-.921-.391-1.937-.645L19 12l5.206-1.302c1.016-.254 1.524-.38 1.937-.645a3 3 0 0 0 .91-.91c.265-.413.392-.921.645-1.937L29 2Z"
        />
        <path
        stroke="#0B3183"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="m29 2 1.302 5.206c.253 1.016.38 1.524.645 1.937a3 3 0 0 0 .91.91c.413.265.921.391 1.937.645L39 12l-5.206 1.302c-1.016.254-1.524.38-1.937.645a2.999 2.999 0 0 0-.91.91c-.265.413-.392.921-.645 1.937L29 22l-1.302-5.206c-.253-1.016-.38-1.524-.645-1.937a2.999 2.999 0 0 0-.91-.91c-.413-.265-.921-.391-1.937-.645L19 12l5.206-1.302c1.016-.254 1.524-.38 1.937-.645a3 3 0 0 0 .91-.91c.265-.413.392-.921.645-1.937L29 2Z"
        />
        <path
        stroke="#0B3183"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="m29 2 1.302 5.206c.253 1.016.38 1.524.645 1.937a3 3 0 0 0 .91.91c.413.265.921.391 1.937.645L39 12l-5.206 1.302c-1.016.254-1.524.38-1.937.645a2.999 2.999 0 0 0-.91.91c-.265.413-.392.921-.645 1.937L29 22l-1.302-5.206c-.253-1.016-.38-1.524-.645-1.937a2.999 2.999 0 0 0-.91-.91c-.413-.265-.921-.391-1.937-.645L19 12l5.206-1.302c1.016-.254 1.524-.38 1.937-.645a3 3 0 0 0 .91-.91c.265-.413.392-.921.645-1.937L29 2Z"
        />
  </svg>
  )
}


export function PartnershipTrustIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={41}
            height={40}
            fill="none"
            {...props}
        >
            <path
            stroke="#0B3183"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={3}
            d="M10.334 33.479h4.35c.567 0 1.131.067 1.681.202l4.597 1.117a7.126 7.126 0 0 0 3.044.071l5.083-.989a7.008 7.008 0 0 0 3.545-1.846l3.596-3.498a2.505 2.505 0 0 0 0-3.614c-.924-.9-2.388-1-3.434-.238l-4.191 3.057c-.6.44-1.33.675-2.082.675h-4.047 2.576c1.452 0 2.628-1.144 2.628-2.556v-.511c0-1.173-.82-2.196-1.99-2.48l-3.975-.966a8.38 8.38 0 0 0-1.976-.236c-1.608 0-4.519 1.331-4.519 1.331l-4.886 2.043m-6.667-.708V34c0 .933 0 1.4.182 1.757.16.313.414.568.728.728.357.182.823.182 1.757.182h1.333c.933 0 1.4 0 1.757-.182.313-.16.568-.415.728-.728.182-.357.182-.824.182-1.757v-9.667c0-.933 0-1.4-.182-1.756a1.667 1.667 0 0 0-.728-.729c-.357-.181-.824-.181-1.757-.181H6.334c-.934 0-1.4 0-1.757.181-.314.16-.569.415-.728.729-.182.356-.182.823-.182 1.756ZM28.986 5.987c-.995-2.081-3.288-3.184-5.518-2.12-2.23 1.065-3.18 3.589-2.247 5.804.577 1.37 2.23 4.029 3.41 5.86.435.678.653 1.016.971 1.214.273.17.614.261.936.25.374-.012.732-.196 1.448-.564 1.936-.997 4.698-2.473 5.882-3.37 1.917-1.453 2.392-4.122.957-6.15-1.435-2.03-3.937-2.23-5.84-.924Z"
            />
        </svg>
    )
}

export function TechEmpowermentIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={41}
            height={40}
            fill="none"
            {...props}
        >
            <path
            stroke="#0B3183"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={3}
            d="M17.333 29.43v3.903a3.333 3.333 0 0 0 6.667 0v-3.902M20.667 3.333V5m-15 15H4M9.833 9.167l-1-1m22.667 1 1-1M37.333 20h-1.666m-5 0c0 5.523-4.478 10-10 10-5.523 0-10-4.477-10-10s4.477-10 10-10c5.522 0 10 4.477 10 10Z"
            />
        </svg>
    )
}

export function InstaIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <path
      fill="#0B3183"
      d="M20 3c-4.617 0-5.196.02-7.01.102-1.809.083-3.044.37-4.126.79a8.333 8.333 0 0 0-3.01 1.961 8.332 8.332 0 0 0-1.962 3.011c-.42 1.082-.707 2.317-.79 4.127C3.02 14.804 3 15.383 3 20c0 4.617.02 5.196.102 7.01.083 1.808.37 3.044.79 4.126a8.333 8.333 0 0 0 1.961 3.01 8.334 8.334 0 0 0 3.011 1.962c1.082.42 2.317.707 4.127.79C14.804 36.98 15.383 37 20 37c4.617 0 5.196-.02 7.01-.102 1.808-.083 3.044-.37 4.126-.79a8.334 8.334 0 0 0 3.01-1.961 8.334 8.334 0 0 0 1.962-3.011c.42-1.082.707-2.317.79-4.127C36.98 25.196 37 24.617 37 20c0-4.617-.02-5.196-.102-7.01-.083-1.809-.37-3.044-.79-4.126a8.334 8.334 0 0 0-1.961-3.01 8.333 8.333 0 0 0-3.011-1.962c-1.082-.42-2.317-.707-4.127-.79C25.196 3.02 24.617 3 20 3Zm0 3.063c4.54 0 5.077.017 6.87.1 1.657.075 2.557.352 3.156.585.794.308 1.36.676 1.955 1.271a5.268 5.268 0 0 1 1.271 1.955c.233.599.51 1.5.586 3.156.082 1.793.099 2.33.099 6.87s-.017 5.077-.1 6.87c-.075 1.657-.352 2.557-.585 3.156a5.266 5.266 0 0 1-1.271 1.955 5.267 5.267 0 0 1-1.955 1.271c-.599.233-1.5.51-3.157.586-1.792.082-2.33.099-6.869.099-4.54 0-5.077-.017-6.87-.1-1.657-.075-2.557-.352-3.156-.585a5.266 5.266 0 0 1-1.955-1.271 5.267 5.267 0 0 1-1.271-1.955c-.233-.599-.51-1.5-.586-3.157-.082-1.792-.099-2.33-.099-6.869 0-4.54.017-5.077.1-6.87.075-1.657.352-2.557.585-3.156a5.267 5.267 0 0 1 1.271-1.955 5.267 5.267 0 0 1 1.955-1.271c.599-.233 1.5-.51 3.156-.586 1.793-.082 2.33-.099 6.87-.099"
    />
    <path
      fill="#0B3183"
      d="M20 25.667a5.667 5.667 0 1 1 0-11.334 5.667 5.667 0 0 1 0 11.334Zm0-14.397a8.73 8.73 0 1 0 0 17.46 8.73 8.73 0 0 0 0-17.46Zm11.115-.345a2.04 2.04 0 1 1-4.08 0 2.04 2.04 0 0 1 4.08 0Z"
    />
  </svg>
    )
}

export function ChevronRightIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m9 18 6-6-6-6"
    />
  </svg>
    )
}