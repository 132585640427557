import React from 'react';

import { AboutCard } from '../components/Cards';

export default function About() {
  return (
    <section id="about" className="about">
      <div className="about-container">
        <span className="title">We are Llamaduck</span>
        <div className="about-card-container">
          <AboutCard image={<div className="about-card-img-placeholder"/>} name="Cam" job="Front-end engineer"/>
          <AboutCard image={<div className="about-card-img-placeholder"/>} name="Sam" job="Back-end engineer"/>
          <AboutCard image={<div className="about-card-img-placeholder"/>} name="Veasey" job="Product designer"/>
        </div>
      </div>
    </section>
  )
}
