import React from 'react';

import { ProjectCard } from '../components/Cards';
import { VerveLogo, ForethoughtLogo, DesignForHumansLogo } from '../assets/Branding';
import { ChevronPrimaryButton } from '../components/Buttons';

export default function Projects() {
  return (
    <section id="projects" className="projects">
      <div className="section-container">
        <div className="projects-header">
          <span className="title">Have a look for yourself</span>
          <span className="subtitle">See how we’ve transformed challenges into solutions with AI – with innovation, collaboration, and real-world success. See the difference we make.</span>
        </div>
        <div className="projects-container">
          <ProjectCard icon={<VerveLogo/>} project="Verve" text="Short two-line maximum description. It doesn’t need to be long" onClick={() => console.log('Project card clicked!')}/>
          <ProjectCard icon={<ForethoughtLogo/>} project="Forethought" text="Short two-line maximum description. It doesn’t need to be long" onClick={() => console.log('Project card clicked!')}/>
          <ProjectCard icon={<DesignForHumansLogo/>} project="Design for Humans" text="Short two-line maximum description. It doesn’t need to be long" onClick={() => console.log('Project card clicked!')}/>
        </div>
        <div className="projects-button-container">
          <ChevronPrimaryButton title="View more projects" onClick={() => console.log('View more projects')}/>
        </div>
      </div>
    </section>
  )
}
