import React from 'react';
import { ChevronRightIcon } from '../assets/Icons';

type ButtonProps = {
    title: string;
    onClick: () => void;
}

export function PrimaryButton(props: ButtonProps) {
  return (
    <button className="primary-button" onClick={props.onClick}><span className="primary-button-text">{props.title}</span></button>
  )
}

export function WidePrimaryButton(props: ButtonProps) {
  return (
    <button className="wide-primary-button" onClick={props.onClick}><span className="primary-button-text">{props.title}</span></button>
  )
}

export function SecondaryButton(props: ButtonProps) {
  return (
    <button className="secondary-button" onClick={props.onClick}><span className="secondary-button-text">{props.title}</span></button>
  )
}

export function ChevronPrimaryButton(props: ButtonProps) {
  return (
    <button className="chevron-primary-button" onClick={props.onClick}>
      <span className="primary-button-text">{props.title}</span>
      <ChevronRightIcon/>
    </button>
  )
}
