import React from 'react';

export default function Statement() {
  return (
    <section className="statement">
      <div className="statement-section-container">
        <span className="statement-title">AI, design, & security</span>
        <span className="statement-subtitle">We make AI accessible and useful for your business with a mix of innovation, creativity, and tech expertise – we're all about solving real problems and boosting your success.</span>
    </div>
    </section>
  )
}
