import React, { useState, useRef } from 'react';
import * as emailjs from '@emailjs/browser';

export function ContactForm() {
  const form = useRef<HTMLFormElement | null>(null);

  const [formData, setFormData] = useState({
    companyName: '',
    name: '',
    email: '',
    interestAreas: {
      customAISolutions: false,
      trainingWorkshops: false,
      aiIntegrationServices: false,
      justBrowsing: false,
    },
    message: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;

    if (type === 'checkbox') {
      const { checked } = e.target as HTMLInputElement;
      setFormData(prev => ({
        ...prev,
        interestAreas: {
          ...prev.interestAreas,
          [name]: checked,
        },
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (form.current) {
      emailjs.sendForm(
        'service_s1ebqxk', // Your EmailJS service ID
        'template_s4dyvee', // Your EmailJS template ID
        form.current, // Form reference
        'Vb3COHYzc0Icmo3bs' // Your EmailJS user ID
      ).then(
        (result) => {
          console.log('Email sent successfully:', result.text);
          resetForm(); // Reset form fields after successful submission
        },
        (error) => {
          console.error('Error sending email:', error.text);
        }
      );
    }
  };

  const resetForm = () => {
    setFormData({
      companyName: '',
      name: '',
      email: '',
      interestAreas: {
        customAISolutions: false,
        trainingWorkshops: false,
        aiIntegrationServices: false,
        justBrowsing: false,
      },
      message: '',
    });

    if (form.current) {
      form.current.reset();
    }
  };

  return (
    <form ref={form} onSubmit={handleSubmit} className="contact-form">
      <div className="form-group">
        <div className="field-container">
          <label className="field-title" htmlFor="companyName">Company Name<span className="required">*</span></label>
          <input
            type="text"
            id="companyName"
            name="companyName"
            placeholder="Company name here"
            value={formData.companyName}
            onChange={handleChange}
            required
          />
        </div>

        <div className="field-container">
          <label className="field-title" htmlFor="name">Name<span className="required">*</span></label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Your name here"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      <div className="form-group">
        <div className="field-container">
          <label className="field-title" htmlFor="email">Email Address<span className="required">*</span></label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Your email here"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      <div className="field-container">
        <label className="field-title" htmlFor="interestAreas">Interest area<span className="required">*</span></label>
        <fieldset className="interest-area">
          <div className="checkbox">
            <input
              type="checkbox"
              id="customAISolutions"
              name="customAISolutions"
              checked={formData.interestAreas.customAISolutions}
              onChange={handleChange}
            />
            <label htmlFor="customAISolutions">Custom AI Solutions</label>
            {/* Hidden field for checkbox value */}
            <input type="hidden" name="customAISolutions" value={formData.interestAreas.customAISolutions ? 'Yes' : 'No'} />
          </div>

          <div className="checkbox">
            <input
              type="checkbox"
              id="trainingWorkshops"
              name="trainingWorkshops"
              checked={formData.interestAreas.trainingWorkshops}
              onChange={handleChange}
            />
            <label htmlFor="trainingWorkshops">Training & Workshops</label>
            {/* Hidden field for checkbox value */}
            <input type="hidden" name="trainingWorkshops" value={formData.interestAreas.trainingWorkshops ? 'Yes' : 'No'} />
          </div>

          <div className="checkbox">
            <input
              type="checkbox"
              id="aiIntegrationServices"
              name="aiIntegrationServices"
              checked={formData.interestAreas.aiIntegrationServices}
              onChange={handleChange}
            />
            <label htmlFor="aiIntegrationServices">AI Integration Services</label>
            {/* Hidden field for checkbox value */}
            <input type="hidden" name="aiIntegrationServices" value={formData.interestAreas.aiIntegrationServices ? 'Yes' : 'No'} />
          </div>

          <div className="checkbox">
            <input
              type="checkbox"
              id="justBrowsing"
              name="justBrowsing"
              checked={formData.interestAreas.justBrowsing}
              onChange={handleChange}
            />
            <label htmlFor="justBrowsing">Just Browsing</label>
            {/* Hidden field for checkbox value */}
            <input type="hidden" name="justBrowsing" value={formData.interestAreas.justBrowsing ? 'Yes' : 'No'} />
          </div>
        </fieldset>
      </div>

      <div className="form-group">
        <div className="message-container">
          <label className="field-title" htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            placeholder="Any messages here"
            rows={6}
            value={formData.message}
            onChange={handleChange}
          />
        </div>
      </div>

      <button className="primary-button" type="submit">
        <span className="primary-button-text">Submit</span>
      </button>
    </form>
  );
}
