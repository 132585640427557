import React from 'react';

import { HeroIllustration } from '../assets/Illustrations';
import { PrimaryButton, SecondaryButton } from '../components/Buttons';

export default function Hero() {
  const navigateToSection = (sectionId: string) => {
    const targetSection = document.getElementById(sectionId);

    if (targetSection) {
      const offsetPosition = targetSection.offsetTop - 120; // Offset by navbar height
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };
  return (
    <section className="hero">
      <div className="section-container">
        <div className="hero-content-container">
          <HeroIllustration />
          <span className="title">Empowering your business with AI</span>
          <span className="subtitle">We manage the AI complexities – letting you concentrate on what you do best.</span>
        </div>
        <div className="hero-button-container">
          <PrimaryButton title="Get in touch" onClick={() => navigateToSection('contact')}/>
          <SecondaryButton title="View portfolio" onClick={() => navigateToSection('projects')}/>
        </div>
      </div>
    </section>
  )
}
