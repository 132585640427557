import React from 'react';
import { DevAISolutionsIllustration, TrainingWorkshopsIllustration, AIIntegrationServicesIllustration } from '../assets/Illustrations';
import { ServiceCardLeftAction, ServiceCardRightAction } from '../components/Cards';

export default function Services() {
  const services = [
    {illustration: <DevAISolutionsIllustration/>, service: "Developing AI Solutions", text: "Custom AI that fits your business perfectly. We build it, you benefit."},
    {illustration: <TrainingWorkshopsIllustration/>, service: "AI Integration Services", text: "Plug AI into your systems. It's smooth, it's smart, and it scales with you."},
    {illustration: <AIIntegrationServicesIllustration/>, service: "Training & Workshops", text: "Get your team up to speed on AI. No fluff, just the stuff you need to know."},
  ];

  return (
    <section id="services" className="services">
      <div className="section-container">
        <span className="title">These are our services</span>
        {services.map((service, index) => {
          const CardComponent = index % 2 === 0 ? ServiceCardRightAction : ServiceCardLeftAction;
          return (
            <CardComponent key={index} illustration={service.illustration} service={service.service} text={service.text}/>
          )
        })}
      </div>
    </section>
  )
}
