import React from 'react';

import { ValueCard } from '../components/Cards';
import { InnovationIcon, PartnershipTrustIcon, TechEmpowermentIcon } from '../assets/Icons';

export default function Values() {
  return (
    <section id="Values" className="values">
      <div className="section-container">
        <span className="title">This is what we value</span>
        <div className="value-card-container">
          <ValueCard icon={<InnovationIcon />} value="Innovation for everyone" text="AI isn't reserved for the few. We bring advanced AI solutions right to your business, ready to shape your future."/>
          <ValueCard icon={<PartnershipTrustIcon />} value="Partnership & trust" text="We're more than just service providers - we're your dedicated partners in innovation, committed to mutual success."/>
          <ValueCard icon={<TechEmpowermentIcon />} value="Empowerment through technology" text="Leveraging AI to eliminate mundane tasks, giving you more time to focus on creativity and what truly matters."/>
        </div>
      </div>
    </section>
  )
}
