import React from 'react';
import './App.css';

// Styling import statements
import './app/styling/Styles.scss';

import Home from './app/pages/Home';

function App() {
  return (
    <Home />
  );
}

export default App;
