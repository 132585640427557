import React from 'react';

import { ContactForm } from '../components/Forms';

export default function Contact() {
  return (
    <section id="contact" className="contact">
      <div className="contact-container">
        <span className="title">Let's work together!</span>
        <ContactForm />
      </div>
    </section>
  )
}
