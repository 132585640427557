import React from 'react';

import { SquareLogo } from '../assets/Branding';
import { InstaIcon } from '../assets/Icons';
import { PrimaryButton } from '../components/Buttons';

export default function Footer() {
  const navigateToSection = (sectionId: string) => {
    const targetSection = document.getElementById(sectionId);

    if (targetSection) {
      const offsetPosition = targetSection.offsetTop - 120; // Offset by navbar height
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };
  return (
    <footer className="footer">
      <div className="footer-container">
        <a href="/" className="footer-logo">
          <div className="logo-container">
            <SquareLogo />
          </div>
          <strong className="footer-logo-text">Llamaduck</strong>
        </a>

        <div className="footer-action-container">
          <InstaIcon />
          <PrimaryButton title="Get in touch" onClick={() => navigateToSection('contact')}/>
          </div>
      </div>
    </footer>
  )
}
