import React from 'react';

import { WidePrimaryButton } from './Buttons';

type ValueCardProps = {
    icon: React.ReactNode;
    value: string;
    text: string;
}

type ServiceCardProps = {
  illustration: React.ReactNode;
  service: string;
  text: string;
}

type ProjectCardProps = {
  icon: React.ReactNode;
  project: string;
  text: string;
  onClick: () => void;
}

type AboutCardProps = {
  image: React.ReactNode;
  name: string;
  job: string;
}

export function ValueCard(props: ValueCardProps) {
  return (
    <div className="value-card">
        {props.icon}
        <span className="card-title">{props.value}</span>
        <span className="card-subtitle">{props.text}</span>
    </div>
  )
}

export function ServiceCardLeftAction(props: ServiceCardProps) {
  const navigateToSection = (sectionId: string) => {
    const targetSection = document.getElementById(sectionId);

    if (targetSection) {
      const offsetPosition = targetSection.offsetTop - 120; // Offset by navbar height
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };
  return (
    <div className="service-card">
      <div className="service-card-content-container">
        <div className="service-card-text-container">
          <span className="service-card-title">{props.service}</span>
          <span className="service-card-subtitle">{props.text}</span>
        </div>
        <WidePrimaryButton title="Get in touch" onClick={() => navigateToSection('contact')}/>
      </div>
      <div className="service-card-illustration-container">
        {props.illustration}
      </div>
    </div>
  )
}

export function ServiceCardRightAction(props: ServiceCardProps) {
  const navigateToSection = (sectionId: string) => {
    const targetSection = document.getElementById(sectionId);

    if (targetSection) {
      const offsetPosition = targetSection.offsetTop - 120; // Offset by navbar height
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };
  return (
    <div className="service-card">
        <div className="service-card-illustration-container">
          {props.illustration}
        </div>
        <div className="service-card-content-container">
          <div className="service-card-text-container">
            <span className="service-card-title">{props.service}</span>
            <span className="service-card-subtitle">{props.text}</span>
          </div>
          <WidePrimaryButton title="Get in touch" onClick={() => navigateToSection('contact')}/>
      </div>
    </div>
  )
}

export function ProjectCard(props: ProjectCardProps) {
  return (
    <div className="project-card" onClick={props.onClick}>
      <div className="project-card-inner-shadow">
        <div className="project-card-icon-container">
          {props.icon}
        </div>
        <div className="project-card-text-container">
          <span className="card-title">{props.project}</span>
          <span className="card-subtitle">{props.text}</span>
        </div>
      </div>
    </div>
  )
}

export function AboutCard(props: AboutCardProps) {
  return (
    <div className="about-card">
      {props.image}

    <div className="project-card-text-container">
      <span className="card-title">{props.name}</span>
      <span className="card-subtitle">{props.job}</span>
    </div>
  </div>
  )
}