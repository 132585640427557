import React from 'react';

import Navbar from '../sections/Navbar';
import Hero from '../sections/Hero';
import Statement from '../sections/Statement';
import Values from '../sections/Values';
import Services from '../sections/Services';
import Projects from '../sections/Projects';
import About from '../sections/About';
import Contact from '../sections/Contact';
import Footer from '../sections/Footer';

export default function Home() {
  return (
    <div>
        <Navbar />
        <Hero />
        <Statement />
        <Values />
        <Services />
        <Projects />
        <About />
        <Contact />
        <Footer />
    </div>
  )
}
